<template>
  <div class="page-file-af">
    
      <div class="custom-info">
        <div class="item" v-for="(item, index) in customInfo" :key="index">
          <span class="label">{{item.label}}</span> 
          <strong>{{item.text}}</strong>
        </div>
      </div>
  </div>
</template>

<script>
import { postForm } from '@/api'
import {getRole} from '@/utils/getRole'

export default {
  data() {
      return {
        curQaType: {},
        customInfo: [],
        curStep: 't2',
        isVolvo: false,
      }
  },
  methods: {
    onSelType(item) {
      console.log(item);
      this.curQaType = item || {};
      this.onSearch();
    },
    
    onSearch() {
      const typeId = this.curQaType.id;
      postForm('/h5/sa_seller/detailCustomer', {
        id: this.id,
        type_id: typeId,
      }).then(res => {
          if (res.status === 1) {
            this.customInfo = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    }
  },
  components: {
  },
  created() {
    this.isVolvo = getRole().isVolvo;

    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.onSearch(true);
  },
}
</script>

<style lang="less">

.page-file-af {
  position: relative;
  padding-bottom: 1rem;

  h3.title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .table {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 0;
    font-size: 0.6rem;
    thead {
      color: #000;
      th.el-table__cell {
        background: rgba(55,126,234,0.1);
      }
    }
    td.el-table__cell {
      padding: 0.4rem 0;
    }
    .cell {
      padding: 0;
    }
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border: 0;
  }
  
  .list-type {
    display: flex;
    flex-wrap: wrap;
    padding: 0.6rem 0;
    a {
      padding: 0.35rem 0;
      min-width: 30%;
      max-width: 33.3%;
      flex: 1;
      text-align: center;
      display: block;

      span {
        display: block;
        font-size: 0.6rem;
        color: #788798;

        &.num {
          color: #000;
        }

        strong {
          font-size: 0.9rem;
          margin-right: 0.1rem;
        }
      }
    }
    .sel {
      border-radius: 0.4rem;
      background: #EBF2FD;
      .num, strong, span {
        color: #377EEA;
      }
    }
  }
  
  .custom-info {
    padding: 0.75rem;
    .item {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #D9D9D9;
      padding: 0.5rem 0;

      span {
        color: #666;
      }
      strong {
        font-weight: 400;
        color: #000;
      }
    }
  }

}

</style>
