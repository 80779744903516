<template>
  <div class="page-text-af">
    <div>
      <div id="list" style="padding: 0.4rem 0">
        <a-table class="list-audio" size="default" rowKey="id"
                                                   :locale="{emptyText: '暂无数据'}"
           :pagination="false"
           :columns="audioClns"
           :rowClassName="rowClassName"
           :customRow="clickTableRow"
           :dataSource="showOrderList">
              <template slot="orderNo">
                <a-tooltip trigger="click">
                  单据号
                  <template slot="title">
                    说明：<br/> 
                    列表中蓝色字体表示该单据对应录音有命中点；黑色字体表示对应录音未命中；<br/>
                    浅色背景色表示显示的录音是该条单据的录音。
                  </template>
                  <a-icon type="question-circle" style="color: #999"/>
                </a-tooltip>
              </template>
              <span slot="tag" slot-scope="text, record">
                <a-tag v-if="text">{{text}}</a-tag>
              </span>
              
          </a-table>

          <div v-if="orderList.length > maxLen"
            style="text-align: center; padding-top: 10px; font-weight: 400;">
            <a @click="toggleExpand"
              style="color: #377EEA; font-size: 13px; display: flex; justify-content: center;">
              <a-icon :type="isExpanded ? 'caret-up' : 'caret-down'"
                style="font-size: 18px; margin-right: 2px;"/>
                <span>{{isExpanded ? '收起' : '展开'}}</span>
            </a>
          </div>
      </div>
    <div class="bg-fixed">
      <div ref="fixed" class="fixed">
        <div v-if="this.recordText.length > 1">
          <div class="box-tab-t">
            <p v-for="(item,idx) in tabs" :key="idx"
              :class="item.id==curTab ? 'sel' : ''"
               @click="onChangeTab(item.id)"
               > <i></i>{{item.title}} </p>
          </div>
        </div>
        <div class="box-tips">
          温馨提示：点击转译错误文字可在线反馈质检问题
        </div>
      </div>
    </div>
    </div>
    <div class="box-text" @scroll="showQA=false">
      <div class="sec" style="border-radius: 0;">
        <el-empty v-if="isEmpty" description="暂无记录"></el-empty>
        <div class="box-chat" ref="text" v-else>
        <p v-for="(item,index) in textList" :id="item.datetime" :key="index"
          :class="selChat==item.datetime? item.role+' sel': item.role">
          <strong>{{item.role}}</strong>
          <span @click.stop="setStartTime(item.datetime)">{{item.message}}</span>
          <a :class="selPop==item.datetime? 'box-tip-btn sel': 'box-tip-btn'"
            @click="$router.push({name: 'qaForm', query: {id, time:item.datetime}})">
            <i class="btn" > 质检问题反馈 </i>
          </a>
        </p>
        </div>
      </div>
      <div class="box-record">
        <div class="fixed">
          <div class="info">
            <span>{{detail.datetime}}</span>
            <span>{{detail.fileSize}}</span>
          </div>
            <!--
          <audio-player ref="audi" :src="detail.url" light/>
            -->
          <audio-player ref="audio"
            :audio-list="[{url: detail.url}].map(elm => elm.url)"
            :show-volume-button="false"
            :show-prev-button="false"
            :show-next-button="false"
            :showPlaybackRate="false"
            :isLoop="false"
            theme-color="#518dff"
            @timeupdate="onPlaying" />
        </div>
      </div>

      <!--
      <div class="box-qa">
        <span v-if="showQA"
          @click="$router.push({name: 'qaForm', query: {id}})">
          反馈 <i class="el-icon-arrow-right"></i> </span>
        <i v-else @click.self="showQA=true" class="el-icon-arrow-left"></i>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import { postForm } from '@/api'
import AudioPlayer from '@liripeng/vue-audio-player'
//import { audioPlayer } from 'vue-md-player'
//import 'vue-md-player/dist/vue-md-player.css'

export default {
  data() {
      return {
        id: '',
        recordText: [],
        textList: [],
        showOrderList: [],
        orderList: [],
        detail: {},
        maxLen: 5,
        isExpanded: true,
        isEmpty: false,
        showQA: false,
        curTab: 0,
        showTab: false,
        tabs: [
          {title: '首次质检', id: 0},
          {title: '二次质检', id: 1},
        ],
        selChat: null,
        curTime: 0,
        duration: 0,
        selPop: 0,
        audioClns: [
          {
            //title: "单据号",
            slots: { title: 'orderNo'},
            width: "110px",
            dataIndex: "order_no"
          },
          {
            title: "单据种类",
            width: "80px",
            dataIndex: "order_type"
          },
          {
            title: "开单时间",
            width: "80px",
            dataIndex: "datetime"
          },
          {
            title: "标签名称",
            dataIndex: "tag_name"
          },
        ]
        
      }
  },
  components: {
    //audioPlayer,
    AudioPlayer,
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.showOrderList =  this.isExpanded? this.orderList : this.orderList.slice(0, this.maxLen);
      const dom = document.getElementById('list');
      dom && dom.scrollIntoView();
    },
    loadOrder(id) {
      postForm('/api/b_sa_check/detailLists', {id}).then(res => {
          if (res.status === 1) {
            this.orderList = res.data || [];
            this.toggleExpand();

            const record = this.orderList[0];
            if (record) {
              this.selectedRowKey = record.id;
              this.loadData(record.id);
            }
          } else {
            this.$message.error(res.msg);
          }
        });

    },
    rowClassName(record, index) {
      return this.selectedRowKey === record.id ? 'sel' : '';
    },
    
    clickTableRow(record, index) {
      return {
          style: {
            cursor: 'pointer',
            color: record.color,
          },
          on: {
             click: () => {
               this.selectedRowKey = record.id;
               this.loadData(record.id);
             }
          }
        }
    },
    
    setStartTime(time, type) {
      //this.selPop = time;
      if (!time) {
        return;
      }
      if (type) {
        this.curTab = this.businessListh[type-1] || {};
      }
      const t = Number(time)/1000;
      const _this = this.$refs.audio;
      if (!_this) {return}
      const offsetLeft =
        (t / _this.$refs.audio.duration) *
        _this.$refs.audioProgressWrap.offsetWidth
      _this.$refs.audioProgress.style.width = offsetLeft + 'px';
      _this.setPointPosition(offsetLeft);
      _this.currentTime = t;
      _this.$refs.audio.currentTime = t;
      _this.play();
    },
    onPlaying(e) {
      const {currentTime, duration} = e.target;
      const curT = currentTime*1000;
      this.curTime = curT;
      this.duration = duration*1000;
      this.setChat(curT);
    },
    setChat(curT) {
      const dom = this.$refs.text;
      const list = this.textList;
      if (!dom || !list.length) {
        return;
      }
      const idx = list.findIndex(o => (o.datetime >= curT));
      const item = list[idx-1];
      if (item) {
        const dt = document.getElementById(item.datetime);
        if(dt) {
          //console.log(curT, dt);
          //dom.scrollTop = dt.offsetTop - 10;
          dt.scrollIntoView({block: 'center'});
          this.selChat = item.datetime;
        }
      } else {
          this.selChat = null;
      }
    },
    onChangeTab(id) {
      if(!this.recordText.length) {
        this.isEmpty = true;
        return;
      }
      this.curTab = id;
      this.textList = this.recordText[id].text || [];
      this.isEmpty = !this.textList.length;
    },
    loadData(id) {
      postForm('/api/b_sa_check/subDetailVoice', {id}).then(res => {
          if (res.status === 1) {
            const {audioUrl, recordText} = res.data;
            this.recordText = recordText || [];
            this.detail = {
              url: audioUrl,
            }
            this.showTab = this.recordText.length > 1;
            this.onChangeTab(0);
          } else {
            this.$message.error(res.msg);
          }
        });
      },
  },
  created() {
    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.loadOrder(id);
  },
}
</script>

<style lang="less" scope>

.page-text-af {
  .bg-fixed {
    /*
    background: url(~@/assets/af_bg_main.png) 0 -3rem no-repeat;
    background-size: 100% auto;
    position: fixed;
    width: 100%;
    z-index: 100;
    */
    .fixed {
    }
    .box-tips {
      margin: 0 0.8rem;
      padding: 0.65rem 1rem;
      text-align: center;
      background: #FFF6E6;
      color: #FFA300;
    }
    .box-tab-t {
      padding: 0 0.8rem;
      margin-bottom: 0.7rem;
      display: flex;
      justify-content: space-between;
      p {
        max-width: 48%;
        display: flex;
        align-items: center;
        flex: 1;
        background: #fff;
        color: #5C5C5C;
        border-radius: 2.3rem;
        height: 2.3rem;
        justify-content: center;
        font-size: 0.7rem;
        margin: 0;
        &.sel {
          color: #0070C0;
          border: 1px solid #0070C0;
        }
      }
    }
  }

.list-audio {
  padding: 0 0.75rem;
  .ant-table {
    font-size: 0.6rem;

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: none;
    }
    .ant-table-placeholder {
      background: transparent;
      min-height: 60px;
      line-height: 60px;
      padding: 0;
      color: #909399;
      border: 0;
    }
    .ant-table-thead > tr > th {
      background: transparent;
      color: #999999;
      font-weight: normal;
      border-bottom: 1px solid #D9D9D9;
    }
    .ant-table-tbody > tr > td {
      border: 0;
    }
    .sel {
      td {
        background: #fff3c4 !important;
      }
    }
    th,td {
      padding: 0.3rem 0.2rem;
    }
    .box-play {
      > span {
        display: flex;
      }
      .ic {
        width: 18px;
        height: 18px;
        margin-right: 3px;
      }
    }
  }
}

.box-text {
  position: relative;
  overflow: auto;
  padding-bottom: 0.5rem;
  .sec {
    margin: 0 0.8rem;
  }

  .box-qa {
    color: #377EEA;
    position: fixed;
    right: 1rem;
    bottom: 7rem;
    background: #fff;
    padding: 0.5rem;
    font-size: 0.7rem;
    border-radius: 1rem;
    font-weight: bold;

    i {
      font-size: 0.8rem;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .upload{
    margin: 1rem 10%;
    width: 80%;
    line-height: 2.5rem;
    padding: 0;
    font-size: 0.8rem;
    border: 0;
  }
  
  .box-chat {
    overflow: auto;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;

    .box-tip-btn {
      background: #DCE7FB !important;
      color: #377EEA !important;
      border-radius: 0;
      display: inline-block;
      padding: 0.3rem;
      position: absolute;
      top: -1.7rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      visibility: hidden;

      &.sel {
        visibility: visible;
      }

      .btn {
        padding-left: 1rem;
        background: url("~@/assets/i_pen.png") no-repeat;
        background-size: 0.8rem 0.8rem;
        font-style: normal;
        white-space: nowrap;
      }
    }

    >p {
      display: flex;
      position: relative;

      strong {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        line-height: 1rem;
        border-radius: 50%;
        background: #DEE4FE;
        text-align: center;
      }
      span {
        background: #eee;
        padding: 0.1rem 0.5rem;
        border-radius: 0.2rem;
      }

      &.sel {
        span {
          color: #f59904;
          font-weight: bold;
        }
      }
      &.A {
        strong {
          margin-right: 0.5rem;
        }
      }

      &.B {
        flex-direction: row-reverse;
        strong {
          margin-left: 0.5rem;
        }
        span {
          background: #EAF1FE;
        }
      }
    }
  }
  .box-record {
    height: 6.5rem;
    .fixed {
      padding-bottom: 1rem;
      padding-top: 0.5rem;
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;

      .info {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
        font-weight: 500;
      }

      .audio-player {
        position: relative;
        margin: 0 1rem;
        padding-bottom: 35px;

        .audio__notice {
          display: none;
        }

        .audio__btn-wrap {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
      .vuemdplayer {
        min-height: 70px;

        .layout {
          position: relative;
          display: block;
        }
        .player-btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background: #377EEA;
          svg {
            fill: #fff;
          }
        }
        .times {
          padding: 0;
          opacity: 0.36;
          display: flex;
          justify-content: space-between;

          span:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
}
</style>
